<template>
  <vs-card>
    <div
      slot:header
      class="flex flex-no-wrap w-full justify-end items-center"
      vs-w="12"
    >
      <div class="mr-2">
        <vs-button
          v-if="canAssignClinic"
          @click="onboardNurseHandler"
          icon-pack="feather"
          icon="icon-edit"
          >Assign</vs-button
        >
        <vs-button v-if="canCreate" @click="redirectToCreateOrg"
          >Create</vs-button
        >
      </div>
      <!-- <div
              class="mr-2"
              v-if="
                $store.state.AppActiveUser.userType === 'superAdmin' ||
                ($store.state.AppActiveUser.userType === 'admin' &&
                  canCreate &&
                  !franchiseId)
              "
            >
              <vs-button
                @click="onboardNurseHandler"
                icon-pack="feather"
                icon="icon-edit"
                >Onboard Nurse</vs-button
              >
            </div> -->
    </div>
    <div style="padding-left: 10px">
      <div id="data-list-list-view" class="data-list-container">
        <div class="lg:hidden mt-3">
          <div class="mb-2 flex flex-wrap">
            <div class="pb-5 pr-2 w-1/2">
              <vs-select
                width="100%"
                v-model="dataTableParams.userType"
                placeholder="User Type"
                autocomplete
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.label"
                  v-for="(item, index) in userType"
                />
              </vs-select>
            </div>
            <div class="pb-5 pr-2 w-1/2">
              <vs-select
                width="100%"
                style="position: relative"
                placeholder="Select Clinic"
                autocomplete
                v-model="dataTableParams.clinic"
              >
                <vs-select-item
                  :key="index"
                  :value="item.clinicId"
                  :text="item.clinicName"
                  v-for="(item, index) in clinics"
                />
              </vs-select>
            </div>
            <!-- <div class="pb-5 pr-2 w-1/2" v-if="this.roleOrgId">
              <vs-select
                width="100%"
                placeholder="Roles"
                autocomplete
                vs-multiple
                v-model="dataTableParams.role"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in roles"
                  :clearable="false"
                />
              </vs-select>
            </div> -->
            <div class="flex w-full">
              <div class="pb-5 pr-2 w-1/2">
                <vs-select
                  width="100%"
                  placeholder="10"
                  autocomplete
                  v-model="dataTableParams.limit"
                >
                  <vs-select-item
                    :key="index"
                    :value="item"
                    :text="item"
                    v-for="(item, index) in limitOptions"
                  />
                </vs-select>
              </div>
              <div class="w-1/2 pr-1">
                <vs-input
                  icon="search"
                  style="width: 100%"
                  placeholder="Search"
                  v-model="searchInputString"
                />
              </div>
            </div>
          </div>
          <ul>
            <li :key="indextr" v-for="(tr, indextr) in users">
              <div
                class="rounded mb-2 p-2 mobile-text relative"
                style="border: 1px solid #e8e8e8"
                @click="editDetailHandler(users[indextr])"
                :class="{'pointer-events-none': !isOrgOwnerOrSuperAdmin}"
              >
                <div>
                  <h4 class="mr-2 mobile-text">
                    {{
                      users[indextr].fullName
                        ? users[indextr].fullName
                        : "N/A" | capitalize
                    }}
                  </h4>
                </div>
                <div class="mr-2 mobile-text">
                  <p>User Type: {{ users[indextr].userType }}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <vs-table
          class="hidden lg:block"
          ref="table"
          :sst="true"
          :total="totalDocs"
          :data="users"
          @search="search"
          @change-page="handleChangePage"
          @sort="handleSort"
          :max-items="dataTableParams.limit"
          search
          :noDataText="noDataText"
        >
          <div slot="header" class="flex flex-wrap flex-grow">
            <div class="pb-5">
              <vs-select
                placeholder="10"
                autocomplete
                id="pageLimit"
                v-model="dataTableParams.limit"
                class="mr-2"
              >
                <vs-select-item
                  :key="index"
                  :value="item"
                  :text="item"
                  v-for="(item, index) in limitOptions"
                />
              </vs-select>
            </div>
            <div class="pb-5">
              <vs-select
                v-model="dataTableParams.userType"
                name="userTypeFilter"
                placeholder="User Type"
                autocomplete
                class="mr-2"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.label"
                  v-for="(item, index) in userType"
                />
              </vs-select>
            </div>
            <div class="pb-5">
              <vs-select
                style="position: relative"
                placeholder="Select Clinic"
                autocomplete
                name="clinicFilter"
                v-model="dataTableParams.clinic"
                class="mr-2"
              >
                <vs-select-item
                  :key="index"
                  :value="item.clinicId"
                  :text="item.clinicName"
                  v-for="(item, index) in clinics"
                />
              </vs-select>
            </div>
            <!-- <div class="pb-5" v-if="this.roleOrgId">
              <vs-select
                placeholder="Roles"
                autocomplete
                vs-multiple
                v-model="dataTableParams.role"
                class="mr-2"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in roles"
                  :clearable="false"
                />
              </vs-select>
            </div> -->
          </div>

          <template slot="thead">
            <vs-th sort-key="dp">DP</vs-th>
            <vs-th sort-key="full_name">Full Name</vs-th>
            <vs-th sort-key="email">Email</vs-th>
            <vs-th sort-key="user_type">User Type</vs-th>
            <vs-th sort-key="role">Role</vs-th>
            <vs-th v-if="isOrgOwnerOrSuperAdmin">Actions</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].profileImage">
                <div
                  class="medium-img-wrapper"
                  v-if="data[indextr].profileImage"
                  @click="open_in_new_tab(data[indextr].profileImage)"
                >
                  <img
                    :src="data[indextr].profileImage"
                    class="img-fluid"
                    alt
                  />
                </div>
                <p v-else>N/A</p>
              </vs-td>
              <vs-td :data="data[indextr].fullName">{{
                data[indextr].fullName | capitalize
              }}</vs-td>
              <vs-td :data="data[indextr].email">{{
                data[indextr].email
              }}</vs-td>
              <vs-td :data="data[indextr].userType">{{
                data[indextr].userType | capitalize
              }}</vs-td>
              <vs-td :data="data[indextr].role">{{
                data[indextr].role | capitalize
              }}</vs-td>
              <vs-td :data="data[indextr]._id" v-if="isOrgOwnerOrSuperAdmin">
                <vs-button
                  style="float: left"
                  type="border"
                  size="small"
                  @click="editDetailHandler(data[indextr])"
                  class="m-1"
                >
                  Edit</vs-button
                >
                <vs-button
                  style="float: left"
                  type="border"
                  size="small"
                  @click="removeHandler(data[indextr])"
                  class="m-1"
                >
                  Remove</vs-button
                >
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <div
          class="
            m-2
            flex
            sm:justify-between
            justify-center
            items-center
            flex-wrap
          "
          style="height: 35px padding-bottom: 30px"
        >
          <span class="mr-2">
            {{
              dataTableParams.page * dataTableParams.limit -
              (dataTableParams.limit - 1)
            }}
            -
            {{
              totalDocs - dataTableParams.page * dataTableParams.limit > 0
                ? dataTableParams.page * dataTableParams.limit
                : totalDocs
            }}
            of {{ totalDocs }}
          </span>
          <div class="pagination-div" v-if="serverResponded">
            <paginate
              :page-count="totalPage"
              :click-handler="handleChangePage"
              class="pagination"
              :page-range="5"
              :prevText="'<'"
              :nextText="'>'"
            ></paginate>
          </div>
        </div>
      </div>
    </div>
  </vs-card>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  name: "organization-table",
  components: {},
  props: {
    canDelete: {
      type: Boolean,
      default: false,
    },
    basicEditRoute: {
      type: String,
      default: "SuperAdminBasicDetails",
    },
    nurseEditRoute: {
      type: String,
      default: "SuperAdminNurseDetails",
    },
    clinicEditRoute: {
      type: String,
      default: "SuperAdminClinicEdit",
    },
    doctorEditRoute: {
      type: String,
      default: "SuperAdminDoctorEdit",
    },
    doctorClinicEditRoute: {
      type: String,
      default: "SuperAdminDoctorClinicsEdit",
    },
    dermaSpecialistEditRoute: {
      type: String,
      default: "SuperAdminDermaSpecialistEdit",
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    createRoute: {
      type: String,
      default: "FranchiseNurseCreate",
    },
    canAssignClinic: {
      type: Boolean,
      default: true,
    },
    inOrgOwner: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      clinics: [{ clinicId: null, clinicName: "All Clinics" }],
      noDataText: "Loading..",
      orgCount: 0,
      totalDocs: 0,
      currentPage: 1,
      roles: [{ value: null, text: "All roles" }],
      searchInputString: null,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 25,
        clinic: null,
        userType: "",
        role: null,
        id: null,
      },
      users: [],
      serverResponded: false,
      limitOptions: [5, 10, 25, 50, 100],
      filterList: [
        { value: "All", label: "All Nurses" },
        { value: "Approved", label: "Approved" },
        { value: "Unapproved", label: "Unapproved" },
      ],
      userType: [
        { value: "", label: "All user type" },
        { value: "basic", label: "Basic user" },
        { value: "doctor", label: "Doctor" },
        { value: "nurse", label: "Nurse" },
      ],
      awaitingSearch: null,
      roleOrgId: null,
      fetchRolesParams: {
        search: "",
        sort: "role",
        dir: "asc",
        page: 1,
        limit: 25,
      },
    };
  },
  methods: {
    ...mapActions("rolesAndPermissions", ["fetchRoles"]),
    ...mapActions("orgOwner", ["removeStaffs"]),
    ...mapActions("organization", [
      "fetchOrganizationUsers",
      "fetchOrganizationClinics",
    ]),
    search(searching) {
      this.searchInputString = searching;
    },
    moment(date) {
      return moment(date);
    },
    getRoles() {
      this.fetchRoles(this.fetchRolesParams).then((res) => {
        this.roles = res.data.data.docs.map((val) => ({
          text: val.role,
          value: val._id,
        }));
      });
    },
    getStaffList() {
      this.$vs.loading();
      this.fetchOrganizationUsers(this.dataTableParams).then((res) => {
        this.$vs.loading.close();
        this.noDataText = "No Users Available";
        this.serverResponded = true;
        this.users = res.data.data.docs;
        this.totalDocs =
          res.data.data.pagination.total || res.data.data.pagination[0].total;
        this.page =
          res.data.data.pagination.page || res.data.data.pagination[0].page;
        this.currentPage =
          res.data.data.pagination.page || res.data.data.pagination[0].page;
        if (res.data.data && res.data.data.orgCount) {
          if (
            this.$store.state.AppActiveUser.userType === "franchise" ||
            this.$store.state.AppActiveUser.userType === "subFranchise"
          )
            this.orgCount = res.data.data.orgCount;
          if (
            this.$store.state.AppActiveUser.userType === "clinic" ||
            this.$store.state.AppActiveUser.userType === "subClinic"
          )
            this.orgCount = 1;
        }
      });
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getStaffList();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getStaffList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getStaffList();
    },
    redirectToCreateOrg() {
      this.$router.push("organizations/create");
    },
    async removeHandler(selectedUser) {
      let orgId = this.dataTableParams.id;
      if (selectedUser.role === "Org Owner") {
        this.$vs.notify({
          title: "Remove failed",
          text: "Org Owner cannot be removed.",
          color: "danger",
        });
      } else {
        this.removeStaffs({
          organizationId: orgId,
          userId: selectedUser.userId,
        }).then((res) => {
          this.getStaffList();
          this.$vs
            .notify({
              title: "Staff removed",
              text: "Staff successfully removed.",
              color: "success",
            })
            .catch((err) => {
              console.log(error);
            });
        });
      }
    },
    editDetailHandler(selectedUser) {
      if (selectedUser.userType === "doctor") {
        this.$router.push({
          name: this.doctorEditRoute,
          params: {
            doctorId: selectedUser.userId,
          },
        });
      }
      if (selectedUser.userType === "nurse") {
        this.$router.push({
          name: this.nurseEditRoute,
          params: {
            nurseId: selectedUser.userId,
          },
        });
      }
      if (selectedUser.userType === "basic") {
        this.$router.push({
          name: this.basicEditRoute,
          params: {
            basicUserId: selectedUser.userId,
          },
        });
      }
      if (selectedUser.userType === "doctorClinic") {
        this.$router.push({
          name: this.doctorClinicEditRoute,
          params: {
            franchiseId: selectedUser.userId,
          },
        });
      }
      if (selectedUser.userType === "dermaSpecialist") {
        this.$router.push({
          name: this.dermaSpecialistEditRoute,
          params: {
            franchiseId: selectedUser.userId,
          },
        });
      }
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
    isOrgOwnerOrSuperAdmin() {
      const userType = this.$store.state.AppActiveUser.userRole.toLowerCase();
      const role = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`);

      if (!role && userType === "superadmin") return true;
      if (!role) return false;
      return role.toLowerCase() === "org owner";
    },
  },
  watch: {
    nurseDetails: function (newVal, oldVal) {
      this.handleSearch(newVal);
    },
    searchInputString: function (newVal, oldVal) {
      this.handleSearch(newVal);
    },
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getStaffList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getStaffList();
      }
    },
    "dataTableParams.filter": function (newfilterVal, oldFilterVal) {
      if (newfilterVal !== oldFilterVal) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.filter = newfilterVal;
        this.getStaffList();
      }
    },
    "dataTableParams.userType": function (newfilterVal, oldFilterVal) {
      if (newfilterVal !== oldFilterVal) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.userType = newfilterVal;
        this.getStaffList();
      }
    },
    "dataTableParams.clinic": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.clinic = newVal;
        this.getStaffList();
      }
    },
    "dataTableParams.role": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.role = newVal;
        this.getStaffList();
      }
    },
  },
  async created() {
    this.roleOrgId = localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
    );
    this.dataTableParams.id = this.roleOrgId
      ? this.roleOrgId
      : this.$route.params.organizationId;
    const res = await this.fetchOrganizationClinics({
      id: this.dataTableParams.id,
    });
    res.data.data.docs.map((clinic) => {
      this.clinics.push({
        clinicId: clinic._id,
        clinicName: clinic.clinicName,
      });
    });

    this.getStaffList();
    this.getRoles();
  },
};
</script>
<style scoped>
.mobile-text {
  color: #164d3d;
  margin: 0.25rem;
}
</style>
